/* this file is shared by multiple file , but still targeting at the regulationCss or the dialog itself */
.gridHeaderContainer {
  display: grid;
  color: rgba(203, 203, 203);
  grid-template-columns: minmax(130px, 140px) minmax(85px, 90px) minmax(
      250px,
      auto
    );
}

.row {
  display: grid;
  grid-template-columns: 170px, 300px 200px 20px;
}

.gridSection {
  font-size: 14px;
  display: grid;
  grid-template-columns: minmax(140px, auto) minmax(90px, auto) 100px 150px;
  grid-template-rows: auto;
}

.type {
  place-self: center left;
}

.deleteBtn {
  grid-column: 5/6;
  grid-row: 1/3;
  place-self: center;
}

/* for ApproveOrRejectDialog -regulation  */

.recordsWatchRegulation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 0.7rem;
  row-gap: 1rem;
  background-color: rgba(224, 224, 224);
  margin-bottom: 0.7rem;
  height: 6rem;
}
.noOfApprover {
  grid-row: span 2;
  align-self: center;
  justify-self: center;
}

.beforeAfterTextContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.beforeEdit {
  min-width: 9rem;
}
.afterEdit {
  min-width: 10rem;
}

.color {
  color: rgba(203, 203, 203);
}

.recordsWatchRegulation > div {
  padding-top: 0.5rem;
}
